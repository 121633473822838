import { Link } from "react-router-dom";
import "./style.scss";

import image1 from "../../assets/new/najam1.jpg";
import image2 from "../../assets/new/najam2.jpg";
import image3 from "../../assets/new/najam3.webp";

import {
  BsCalendar3,
  BsFillCreditCardFill,
  BsFillPatchCheckFill,
  BsFillPlayFill,
} from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { Helmet } from "react-helmet";

const NajamUredaja = () => {
  return (
    <>
      <Helmet>
        <title>Iznajmljivanje strojeva za čišćenje | Clean Team</title>
      </Helmet>

      <div className="homePage">
        <div className="headerImage">
          <img src={image3} alt="Kemijsko čišćenje" />
          <div className="imageOverlay"></div>
          <div className="imageText">
            <h2>
              Clean Team
              <br />
              Iznajmljivanje strojeva za čišćenje
            </h2>
            <div className="text">
              <p>Kontaktirajte nas za besplatnu ponudu i rezervaciju termina</p>
            </div>
            <a href="https://wa.me/385997448333">
              <BsCalendar3 />
              Rezerviraj termin
            </a>
          </div>
        </div>

        <div className="homePageAbout">
          <h2>
            Pružamo usluge iznajmljivanja strojeva za čišćenje sa mogućom
            dostavom na području grada Zagreba i zagrebačke županije
          </h2>
          <div className="homePageAbout__text">
            <p>
              Trebate profesionalnu opremu za čišćenje, ali nemate potrebu za
              stalnim vlasništvom? Nudimo vam uslugu{" "}
              <span>iznajmljivanja visokokvalitetnih strojeva za čišćenje</span>{" "}
              koji su idealni za dubinsko čišćenje namještaja, tepiha, podova i
              drugih površina u vašem prostoru.
            </p>
          </div>
          <h3>
            <BsFillPlayFill />
            Cijene najma strojeva za čišćenje kreću se već od 25€ po danu,
            kontaktirajte nas za više informacija
          </h3>
        </div>

        <div className="homePageServices">
          <div className="servicesSwiper">
            <div className="imageSwiper">
              <div className="image">
                <img src={image1} alt="Kemijsko čišćenje fotelje" />
                <div className="imageOverlay"></div>
              </div>
             
            </div>
          </div>
          <div className="servicesContent">
            <h2>Što nudimo?</h2>
            <ul>
              <li>
                <span>Karcher Puzzi 10/1:</span>Savršen za dubinsko
                čišćenje namještaja.
              </li>
              <li>
                <span>Karcher BDS 43/DUO:</span>Idealan za temeljito
                čišćenje podova svih vrsta.
              </li>
              <li>
                <span>Dodatna oprema i sredstva za čišćenje:</span>Svaki
                uređaj dolazi s potrebnim dodacima i sredstvima za rad, kako
                biste odmah mogli započeti s čišćenjem.
              </li>
            </ul>
          </div>
        </div>

        <div className="homePageWhyUs">
          <div className="whyUsContent">
            <h2>Stručna podrška</h2>
            <p>
              Osim same opreme, osiguravamo i stručnu podršku te upute za
              korištenje uređaja kako biste postigli maksimalne rezultate
              čišćenja. Naš tim uvijek je dostupan za savjet ili pomoć,
              osiguravajući da imate sve što vam je potrebno za učinkovito i
              jednostavno čišćenje.
            </p>
          </div>
          <div className="whyUsImage">
            <img src={image2} alt="Čišćenje" />
          </div>
        </div>

        <div className="homePageAbout">
          <h2>Jednostavno rezervirajte</h2>
          <div className="homePageAbout__text">
            <p>
              Kontaktirajte nas za rezervaciju, a naš tim pomoći će vam u
              odabiru uređaja koji najbolje odgovara vašim potrebama.
            </p>
          </div>
        </div>
        <section>
          <div className="column">
            <BsFillCreditCardFill />
            <h3>
              Plaćanje karticama <br /> i gotovinom
            </h3>
          </div>
          <div className="column">
            <BsFillPatchCheckFill />
            <h3>Iskustvo i profesionalnost</h3>
          </div>
          <div className="column">
            <FaHandshake />
            <h3>
              Dolazak na adresu <br /> uključen u cijenu
            </h3>
          </div>
        </section>
      </div>
    </>
  );
};

export default NajamUredaja;
