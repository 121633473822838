// import { NavLink } from 'react-router-dom';
// import './navbar.scss';
// import { BsCalendar3, BsQuestionLg, BsFillTelephoneFill } from 'react-icons/bs';
// import { AiOutlineHome } from 'react-icons/ai';
// import { GiVacuumCleaner } from "react-icons/gi";



// import logo from '../../assets/logoCT.png';

// const Navbar = () => {
//   return (
//     <div className="navbar">
//       <NavLink to='/' className="logo">
//         <img src={logo} alt="Clean Team Logo" />
//       </NavLink>

//       <nav>
//         <NavLink to="/">
//           <AiOutlineHome />
//           Dubinsko čišćenje
//         </NavLink>

//         <NavLink to="strojno-ciscenje">
//           <GiVacuumCleaner />
//           Strojno čišćenje podova
//         </NavLink>

//         <NavLink to="najam-uredaja">
//           <GiVacuumCleaner />
//           Najam uređaja
//         </NavLink>

//         <NavLink to="odrzavanje-poslovnih-prostora">
//           <GiVacuumCleaner />
//           Održavanje poslovnih prostora
//         </NavLink>

//         {/* <hr /> */}

//         <a href="https://wa.me/385997448333">
//           <BsCalendar3 />
//           Rezerviraj 
//           termin
//         </a>

//         <a href="tel:+385997448333" className='phone'>
//           <BsFillTelephoneFill/>
//           Nazovi
//         </a>
//       </nav>
//     </div>
//   );
// };

// export default Navbar;
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './navbar.scss';
import { BsCalendar3, BsFillTelephoneFill } from 'react-icons/bs';
import { AiOutlineHome } from 'react-icons/ai';
import { GiVacuumCleaner } from 'react-icons/gi';
import { FaBars, FaTimes } from 'react-icons/fa';

import logo from '../../assets/logoCT.png';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="navbar">
      <NavLink to="/" className="logo">
        <img src={logo} alt="Clean Team Logo" />
      </NavLink>

      <button className="hamburger" onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </button>

      <nav className={isMenuOpen ? 'nav-menu open' : 'nav-menu'}>
        <NavLink to="/" onClick={() => setIsMenuOpen(false)}>
       
          Dubinsko čišćenje
        </NavLink>
        <NavLink to="strojno-ciscenje" onClick={() => setIsMenuOpen(false)}>
      
          Strojno čišćenje podova
        </NavLink>
        <NavLink to="najam-uredaja" onClick={() => setIsMenuOpen(false)}>
        
          Najam uređaja
        </NavLink>
        <NavLink to="odrzavanje-poslovnih-prostora" onClick={() => setIsMenuOpen(false)}>
        
          Održavanje poslovnih prostora
        </NavLink>
        <a href="https://wa.me/385997448333" onClick={() => setIsMenuOpen(false)}>
          <BsCalendar3 />
          Rezerviraj termin
        </a>
       
      </nav>
    </div>
  );
};

export default Navbar;