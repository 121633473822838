import { Helmet } from 'react-helmet';
import './legal.scss';

const CookiesPage = () => {
  return (
    <>
      <Helmet>
        <title>Kolačići | Clean Team</title>
      </Helmet>

      <div className="legal">
        <h2>Kolačići</h2>
        <p>
          Kolačići su informacije spremljene na Vaše računalo od strane web
          stranice koju posjetite. Kolačići obično spremaju Vaše postavke,
          postavke za web stranicu, kao što su preferirani jezik ili adresa.
          Kasnije, kada opet otvorite istu web stranicu internet preglednik
          šalje natrag kolačiće koji pripadaju toj stranici. Ovo omogućava
          stranici da prikaže informacije prilagođene Vašim potrebama.
        </p>
        <p>
          Kolačići mogu spremati širok pojas informacija uključujući osobne
          informacije (kao što je Vaše ime ili e-mail adresa). Ipak, ova
          informacija može biti spremljena jedino ako Vi to omogućite – web
          stranice ne mogu dobiti pristup informacijama koji im Vi niste dali i
          ne mogu pristupiti drugim datotekama na Vašem računalu. Zadane
          aktivnosti spremanja i slanja kolačića Vama nisu vidljive. Ipak,
          možete promjeniti Vaše postavke internet preglednika da možete sami
          birati hoćete li zahtjeve za spremanje kolačića odobriti ili odbiti,
          pobrišete spremljene kolačiće automatski pri zatvaranju internet
          preglednika i slično.
        </p>
      </div>
    </>
  );
};

export default CookiesPage;
