import { Link } from "react-router-dom";
import "./style.scss";


import image1 from "../../assets/new/strojno1.webp";
import image2 from "../../assets/new/strojno2.webp";
import image3 from "../../assets/new/strojno3.webp";

import {
  BsCalendar3,
  BsFillCreditCardFill,
  BsFillPatchCheckFill,
  BsFillPlayFill,
} from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { Helmet } from "react-helmet";

const StrojnoCiscenje = () => {
  return (
    <>
      <Helmet>
        <title>Strojno čišćenje podova | Clean Team</title>
      </Helmet>

      <div className="homePage">
        <div className="headerImage">
          <img src={image1} alt="Kemijsko čišćenje" />
          <div className="imageOverlay"></div>
          <div className="imageText">
            <h2>
              Clean Team
              <br />
              Strojno čišćenje podova
            </h2>
            <div className="text">
              <p>Kontaktirajte nas za besplatnu ponudu i rezervaciju termina</p>
            </div>
            <a href="https://wa.me/385997448333">
              <BsCalendar3 />
              Rezerviraj termin
            </a>
          </div>
        </div>

        <div className="homePageAbout">
          <h2>
            Pružamo usluge strojnog čišćenja podova na području grada Zagreba i
            Zagrebačke županije
          </h2>
          <div className="homePageAbout__text">
            <p>
              Specijalizirani smo za{" "}
              <span>
                profesionalno strojno čišćenje i održavanje svih vrsta podnih
                obloga
              </span>{" "}
              – u stambenim, poslovnim i industrijskim prostorima. Bez obzira na
              to radi li se o skladištima, halama, garažama ili uredima, pružamo
              vrhunsku uslugu koja garantira čistoću i dugotrajnost podova.
            </p>
          </div>
        
        </div>

        <div className="homePageServices">
          <div className="servicesSwiper">
            <div className="imageSwiper">
              <div className="image">
                <img src={image2} alt="Kemijsko čišćenje fotelje" />
                <div className="imageOverlay"></div>
              </div>
            
            </div>
          </div>
          <div className="servicesContent">
            <p>
              Naše usluge omogućuju ne samo besprijekornu čistoću već i
              produžuju vijek trajanja podnih obloga. Posebnu pažnju posvećujemo
              uklanjanju tvrdokornih mrlja i nečistoća koje je teško odstraniti
              standardnim metodama. Čisti podovi nisu samo stvar estetike – oni
              povećavaju sigurnost i ostavljaju bolji dojam na Vaše goste ili
              klijente.
            </p>
            <p>
              Naš tim prilagođava raspored čišćenja kako bi se uskladili s vašim
              dnevnim obavezama i osigurali minimalno ometanje. Zadovoljstvo
              naših klijenata najbolji je pokazatelj kvalitete naših usluga.
            </p>
          </div>
        </div>

        <div className="homePageWhyUs">
          <div className="whyUsContent">
            <h2>Zašto odabrati nas?</h2>
            <ul>
              <li>
                <span>Moderni strojevi i tehnike: </span>Naša oprema omogućuje
                dubinsko čišćenje i brzo sušenje podova, uz minimalno ometanje
                vaših svakodnevnih aktivnosti.
              </li>
              <li>
                <span>Prilagođen pristup: </span>Prije svakog zahvata
                procjenjujemo stanje vaših podova i biramo optimalna sredstva i
                metode za najbolje rezultate.
              </li>
              <li>
                <span>Dubinska čistoća: </span>Uklanjamo tvrdokorne mrlje i
                nečistoće koje su standardnim metodama teško uklonjive.
              </li>
              <li>
                <span>Produženi vijek trajanja podova: </span>Naše usluge ne
                samo da osiguravaju besprijekornu čistoću, već i održavaju
                podove u savršenom stanju kroz dulje vrijeme.
              </li>
              <li>
                <span>Estetika i sigurnost: </span>Čisti podovi ne samo da
                izgledaju bolje, već smanjuju rizik od klizanja i ostavljaju
                pozitivan dojam na vaše goste ili klijente.
              </li>
            </ul>
          </div>
          <div className="whyUsImage">
            <img src={image3} alt="Čišćenje" />
          </div>
        </div>

        <div className="homePageAbout">
          <h2>Prilagođeni raspored za vaše potrebe</h2>
          <div className="homePageAbout__text">
            <p>
              Razumijemo važnost neometanog rada, zato prilagođavamo termine
              čišćenja vašem rasporedu kako bismo osigurali minimalne smetnje.
              Vaše zadovoljstvo naš je prioritet, a kvaliteta usluge naš
              zaštitni znak.
            </p>
          </div>
          <h3>
            <BsFillPlayFill />
            Kontaktirajte nas danas i uvjerite se zašto smo vodeći izbor za
            strojno čišćenje podova u Zagrebu i okolici!
          </h3>
        </div>
        <section>
          <div className="column">
            <BsFillCreditCardFill />
            <h3>
              Plaćanje karticama <br /> i gotovinom
            </h3>
          </div>
          <div className="column">
            <BsFillPatchCheckFill />
            <h3>Iskustvo i profesionalnost</h3>
          </div>
          <div className="column">
            <FaHandshake />
            <h3>
              Dolazak na adresu <br /> uključen u cijenu
            </h3>
          </div>
        </section>
      </div>
    </>
  );
};

export default StrojnoCiscenje;
