import './footer.scss';

import logo from '../../assets/logoCT.png';

import { BsFillTelephoneFill } from 'react-icons/bs';
import { MdMail } from 'react-icons/md';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
      <div className="logo">
        <img src={logo} alt="Clean Team Logo" />
      </div>
      <div className="contact">
        <a href="tel:+385997448333">
          <BsFillTelephoneFill />
          +385 99 744 83 33
        </a>
        <a href="mailto:info@dubinskociscenje.hr">
          <MdMail />
          info@dubinskociscenje.hr
        </a>
      </div>
      <div className="links">
        <Link to="cesta-pitanja">Često postavljana pitanja</Link>
        <Link to="zastita-privatnosti">Zaštita privatnosti</Link>
        <Link to="kolacici">Kolačići</Link>
      </div>
      <div className="social">
        <Link to="https://www.facebook.com/cleanteam.zg" target='_blank'>
          <FaFacebookF />
        </Link>
        <Link to="https://www.instagram.com/cleanteam.zg/" target='_blank'>
          <FaInstagram />
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
