import { Helmet } from 'react-helmet';
import './legal.scss';

const PrivacyPage = () => {
  return (
    <>
      <Helmet>
        <title>Zaštita osobnih podataka | Clean Team</title>
      </Helmet>

      <div className="legal">
        <h2>Zaštita osobnih podataka</h2>
        <p>
          Slanjem upita, potpisom ugovora ili neposrednim kontaktom, klijent je
          suglasan da Perfect solution, obrt za usluge dobrovoljno stavlja na
          raspolaganje svoje i podatke trgovačkog društva koje predstavlja ili
          zastupa. Klijent dozvoljava da se isti koriste u cilju zaštite osobnih
          interesa u svim poslovima koje ugovara s Perfect solution.
        </p>
        <p>
          Perfect solution obavezuje se pružati zaštitu osobnim podacima kupaca,
          na način da prikuplja samo nužne, osnovne podatke o
          kupcima/korisnicima koji su nužni za ispunjenje naših obveza;
          informira kupce o načinu korištenja prikupljenih podataka, redovito
          daje kupcima mogućnost izbora o upotrebi njihovih podataka,
          uključujući mogućnost odluke žele li ili ne da se njihovo ime ukloni s
          lista koje se koriste za marketinške kampanje. Svi se podaci o
          korisnicima strogo čuvaju i dostupni su samo djelatnicima kojima su ti
          podaci nužni za obavljanje posla. Svi djelatnici Perfect solution i
          poslovni partneri odgovorni su za poštivanje načela zaštite
          privatnosti.
        </p>
        <p>
          Skupljanje i obrada osobnih podataka uključuje i prosljeđivanje
          osobnih podataka klijenta trećim osobama i trgovačkim društvima u
          Republici Hrvatskoj i inozemstvu. Trećim osobama smatraju se sve
          fizičke osobe i trgovačka društva koje su neophodne za realizaciju
          ugovorenih poslova. Klijent je suglasan da Perfect solution, u skladu
          s gore navedenim uvjetima, koristi osobne i/ili podatke trgovačkog
          društva dvije godine nakon isteka potpisanog ugovora.
        </p>

        <ul>
          <li>
            <strong> Perfect solution, obrt za usluge, vl. Matija Babić</strong>
          </li>
          <li>Sopnička ulica 41, 10360 Sesvete, Hrvatska</li>
          <li>OIB: 19173373157</li>
        </ul>
      </div>
    </>
  );
};

export default PrivacyPage;
