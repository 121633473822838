import "./faqPage.scss";

import image1 from "../../assets/image5.webp";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet";

const FaqPage = () => {
  return (
    <>
      <Helmet>
        <title>Često postavljana pitanja | Clean Team</title>
      </Helmet>

      <div className="faqPage">
        <div className="faqPageHeader">
          <img src={image1} alt="Kemijsko čišćenje madraca" />
          <div className="imageOverlay"></div>
          <div className="imageText">
            <h2>
              Često postavljana pitanja
            </h2>
            <div className="text">
              <p>
                Pronađi odgovore na neke od često postavljanih
                 pitanja.
              </p>
            </div>
          </div>
        </div>
        <div className="questions">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Što je dubinsko profesionalno čišćenje namještaja?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Dubinsko profesionalno čišćenje namještaja je postupak kojim se
                koristi specijalizirana tehnika i oprema kako bi se temeljito
                očistio namještaj od mrlja, prljavštine, prašine, grinja i
                neugodnih mirisa.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Koji su benefiti dubinskog čišćenja namještaja?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Dubinsko čišćenje namještaja produžuje životni vijek namještaja,
                uklanja mrlje, poboljšava higijenu, uklanja neugodne mirise te
                osvježava i obnavlja izgled namještaja.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Kako se provodi postupak dubinskog čišćenja namještaja?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Naš stručni tim koristi specijaliziranu opremu i profesionalne
                metode čišćenja prilagođene vrsti i stanju namještaja. To može
                uključivati usisavanje, pranje, upotrebu parne ili ekstrakcijske
                metode čišćenja, te primjenu ekoloških pH neutralnih sredstva za
                čišćenje.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Kako mogu znati treba li mi dubinsko čišćenje namještaja?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Ako primijetite mrlje, neugodne mirise, prljavštinu koju je
                teško ukloniti ili općenito želite obnoviti izgled svog
                namještaja, vjerojatno je vrijeme za dubinsko čišćenje.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Koliko često trebam obavljati dubinsko čišćenje namještaja?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Preporučuje se obavljati dubinsko čišćenje namještaja barem
                jednom godišnje. Međutim, frekvencija ovisi o intenzitetu
                korištenja namještaja i prisutnosti kućnih ljubimaca ili
                alergena.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Je li postupak dubinskog čišćenja siguran za moj namještaj?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Da, naši postupci čišćenja su sigurni za većinu vrsta
                namještaja. Naš tim ima iskustva u radu s različitim
                materijalima i koristi prilagođene pristupe kako bi se osigurala
                sigurnost i učinkovitost čišćenja.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Koliko dugo traje postupak dubinskog čišćenja?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Vrijeme trajanja postupka ovisi o veličini i stanju namještaja.
                Manji komadi namještaja mogu se očistiti u roku od nekoliko
                sati, dok veći komadi ili složeniji problemi mogu zahtijevati
                više vremena. Naši tehničari će vam dati točne informacije prije
                početka postupka.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default FaqPage;
