import { Link } from "react-router-dom";
import "./homePage.scss";

import image1 from "../../assets/image1.webp";
import image2 from "../../assets/image2.webp";
import image4 from "../../assets/image4.webp";

import {
  BsCalendar3,
  BsFillCreditCardFill,
  BsFillPatchCheckFill,
  BsFillPlayFill,
} from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { Helmet } from "react-helmet";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Dubinsko čišćenje | Clean Team</title>
      </Helmet>

      <div className="homePage">
        <div className="headerImage">
          <img src={image2} alt="Kemijsko čišćenje" />
          <div className="imageOverlay"></div>
          <div className="imageText">
            <h2>
              Clean Team
              <br />
              Dubinsko čišćenje namještaja
            </h2>
            <div className="text">
              <p>Kontaktirajte nas za besplatnu ponudu i rezervaciju termina</p>
            </div>
            <a href="https://wa.me/385997448333">
              <BsCalendar3 />
              Rezerviraj termin
            </a>
          </div>
        </div>

        <div className="homePageAbout">
          <h2>
            Pružamo usluge dubinskog čišćenja namještaja i strojnog čišćenja
            tepiha na području grada Zagreba i zagrebačke županije
          </h2>
          <div className="homePageAbout__text">
            <p>
              Vaš <span>namještaj</span> je važan dio vašeg doma ili poslovnog
              prostora, ali tijekom vremena može postati prljav, istrošen ili
              imati neugodan miris.
            </p>
          </div>
          <h3>
            <BsFillPlayFill />
            Specijalizirani smo za obnovu i osvježavanje Vašeg namještaja kako
            bi izgledao kao nov.
          </h3>
        </div>

        <div className="homePageServices">
          <div className="servicesSwiper">
            <div className="imageSwiper">
              <div className="image">
                <img src={image1} alt="Kemijsko čišćenje fotelje" />
                <div className="imageOverlay"></div>
              </div>
              {/* <div className="image">
                <img src={image5} alt="Kemijsko čišćenje madraca" />
                <div className="imageOverlay"></div>
              </div> */}
            </div>
          </div>
          <div className="servicesContent">
            <p>
              Sigurnost i higijena: Naši postupci čišćenja su sigurni za Vaše
              zdravlje i okoliš. Koristimo ekološki prihvatljive proizvode koji
              su neškodljivi za Vas i Vaše kućne ljubimce.
            </p>
            <p>
              Povjerenje i pouzdanost: Naša tvrtka ima dugogodišnje iskustvo u
              industriji čišćenja. Možete se osloniti na našu stručnost i
              posvećenost pružanju vrhunske usluge.
            </p>
          </div>
        </div>

        <div className="homePageWhyUs">
          <div className="whyUsContent">
            <h2>Očuvanje kvalitete</h2>
            <p>
              Redovito dubinsko čišćenje produžuje životni vijek vašeg
              namještaja. Eliminiramo naslage prašine i nečistoće koje mogu
              oštetiti tkaninu ili materijal namještaja.{" "}
            </p>
          </div>
          <div className="whyUsImage">
            <img src={image4} alt="Čišćenje" />
          </div>
        </div>

        <div className="homePageAbout">
          <h2>Pouzdanost i profesionalnost</h2>
          <div className="homePageAbout__text">
            <p>
              Kontaktirajte nas danas kako biste dogovorili termin za
              profesionalno dubinsko čišćenje Vašeg namještaja. Prepustite nam
              brigu o njemu i uživajte u čistom, svježem i obnovljenom
              namještaju koji će uljepšati Vaš prostor.
            </p>
          </div>
          <h3>
            <BsFillPlayFill />
            Rezervirajte dubinsko čišćenje putem naše web stranice ili telefona.
          </h3>
        </div>
        <section>
          <div className="column">
            <BsFillCreditCardFill />
            <h3>
              Plaćanje karticama <br /> i gotovinom
            </h3>
          </div>
          <div className="column">
            <BsFillPatchCheckFill />
            <h3>Iskustvo i profesionalnost</h3>
          </div>
          <div className="column">
            <FaHandshake />
            <h3>
              Dolazak na adresu <br /> uključen u cijenu
            </h3>
          </div>
        </section>
      </div>
    </>
  );
};

export default HomePage;
