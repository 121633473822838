import { Link } from "react-router-dom";
import "./style.scss";

import image1 from "../../assets/new/odrzavanje1.jpg";
import image2 from "../../assets/new/odrzavanje2.jpg";
import image3 from "../../assets/new/odrzavanje3.jpg";

import {
  BsCalendar3,
  BsFillCreditCardFill,
  BsFillPatchCheckFill,
  BsFillPlayFill,
} from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { Helmet } from "react-helmet";

const OdrzavanjePoslovnihProstora = () => {
  return (
    <>
      <Helmet>
        <title>Održavanje poslovnih prostora | Clean Team</title>
      </Helmet>

      <div className="homePage">
        <div className="headerImage">
          <img src={image3} alt="Kemijsko čišćenje" />
          <div className="imageOverlay"></div>
          <div className="imageText">
            <h2>
              Clean Team
              <br />
              Održavanje poslovnih prostora
            </h2>
            <div className="text">
              <p>Kontaktirajte nas za besplatnu ponudu i rezervaciju termina</p>
            </div>
            <a href="https://wa.me/385997448333">
              <BsCalendar3 />
              Rezerviraj termin
            </a>
          </div>
        </div>

        <div className="homePageAbout">
          <h2>
            Clean Team pruža vrhunske usluge održavanja poslovnih prostora u
            Zagrebu i Zagrebačkoj županiji.
          </h2>
          <div className="homePageAbout__text">
            <p>
              Bez obzira na veličinu ili specifične potrebe vašeg prostora,
              osiguravamo čisto i ugodno radno okruženje koje ostavlja odličan
              dojam na klijente i zaposlenike.
            </p>
          </div>
          <h3>
            <BsFillPlayFill />
            Prilagođavamo se vašim potrebama kako bismo vam pružili
            besprijekornu uslugu uz fleksibilan raspored čišćenja.
          </h3>
        </div>

        <div className="homePageServices">
          <div className="servicesSwiper">
            <div className="imageSwiper">
              <div className="image">
                <img src={image1} alt="Kemijsko čišćenje fotelje" />
                <div className="imageOverlay"></div>
              </div>
             
            </div>
          </div>
          <div className="servicesContent">
            <h2>Što uključuju naše usluge?</h2>
            <ul>
              <li>
                <span>Redovno čišćenje:</span>Uredi, hodnici, sanitarni
                čvorovi i zajedničke prostorije uvijek su u savršenom stanju.
              </li>
              <li>
                <span>Dubinsko čišćenje:</span>Tepisi, podovi i namještaj
                temeljito se čiste kako bi prostor izgledao profesionalno i
                uredno.
              </li>
              <li>
                <span>Ekološka sredstva za čišćenje:</span>Koristimo
                isključivo sredstva koja su sigurna za zdravlje i okoliš.
              </li>
            </ul>
          </div>
        </div>

        <div className="homePageWhyUs">
          <div className="whyUsContent">
            <h2>Zašto odabrati Clean Team?</h2>
            <ul>
              <li>
                <span>Iskustvo i stručnost:</span> Naše dugogodišnje
                iskustvo u održavanju zdravstvenih ustanova, klinika, ureda i
                drugih poslovnih prostora jamči visoke standarde kvalitete.
              </li>
              <li>
                <span>Fleksibilnost:</span> Prilagođavamo se vašim potrebama
                i rasporedu kako bismo osigurali neometano poslovanje.
              </li>
              <li>
                <span>Prilagođeni paketi usluga:</span> Svaki klijent dobiva
                rješenje koje najbolje odgovara njegovom budžetu i specifičnim
                zahtjevima.
              </li>
            </ul>
          </div>
          <div className="whyUsImage">
            <img src={image2} alt="Čišćenje" />
          </div>
        </div>

        <div className="homePageAbout">
          <h2>Pouzdanost i profesionalnost</h2>
          <div className="homePageAbout__text">
            <p>
              Ne prepuštajte čistoću radnog prostora slučaju. Javite nam se i
              zajedno ćemo osmisliti plan održavanja koji zadovoljava vaše
              potrebe.{" "}
              <span>Čisto i uredno radno okruženje je naša misija!</span>
            </p>
          </div>
        </div>
        <section>
          <div className="column">
            <BsFillCreditCardFill />
            <h3>
              Plaćanje karticama <br /> i gotovinom
            </h3>
          </div>
          <div className="column">
            <BsFillPatchCheckFill />
            <h3>Iskustvo i profesionalnost</h3>
          </div>
          <div className="column">
            <FaHandshake />
            <h3>
              Dolazak na adresu <br /> uključen u cijenu
            </h3>
          </div>
        </section>
      </div>
    </>
  );
};

export default OdrzavanjePoslovnihProstora;
